<template>
  <div class="estate_page relative noto w900 relative-center pb-10">
    <h1 class="my-10">物件のご案内</h1>
    <v-row>
      <v-col cols="12">
        <div class="bolf title inline-block px-4">売買</div>
      </v-col>
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        v-for="(p, i) in parts1"
        :key="i"
        class="py-10"
      >
        <router-link :to="p.a">
          <div
            class="f-22 rounded w350 relative-center flex-center f-white box-shadow py-10 bg-blue"
          >
            <div>
              <v-icon class="f-40 f-white mb-3">{{ p.i }}</v-icon
              ><br />
              <div>{{ p.t }}</div>
              <div class="f-20 bold">
                {{ count[i].toLocaleString() }}<span class="f-11 pl-2">件</span>
              </div>
            </div>
          </div>
        </router-link>
      </v-col>
      <v-col cols="12">
        <div class="bolf title inline-block px-4 pt-5">賃貸</div>
      </v-col>
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        v-for="(p, i) in parts2"
        :key="`parts2-${i}`"
        class="py-10"
      >
        <router-link :to="p.a">
          <div
            class="f-22 rounded w350 relative-center flex-center f-white box-shadow py-10 bg-orange"
          >
            <div>
              <v-icon class="f-40 f-white mb-3">{{ p.i }}</v-icon
              ><br />
              <div>{{ p.t }}</div>
              <div class="f-20 bold">
                {{ count[i + 3].toLocaleString()
                }}<span class="f-11 pl-2">件</span>
              </div>
            </div>
          </div>
        </router-link>
      </v-col>

      <v-col cols="12" xl="4" lg="4" md="4" sm="12" class="py-10">
        <a href="https://line.me/R/ti/p/%40698vfgqq" target="_blank">
          <div
            class="f-22 rounded w350 relative-center flex-center f-white box-shadow py-10 square-box"
            style="background-color: #228b22"
          >
            <div>
              <div class="f-12 mb-2">ＬＩＮＥで物件検索！</div>
              <div class="f-09 mb-1">クレボＬＩＮＥ公式</div>
              <img
                :src="require('@/assets/img/line.png')"
                style="max-height: 100px"
              />
              <div class="f-10 px-4 mt-1">
                LINEから物件をお問合せいただくと、仲介手数料10％引！
              </div>
            </div>
          </div>
        </a>
      </v-col>
    </v-row>

    <v-card width="850" class="text-center relative-center pa-4 mb-8 mt-12">
      <div class="inline-block text-left">
        <div class="mb-8">
          掲載案件以外にもご紹介可能な物件が多数ございますので、ご希望の折はご連絡くださいませ
        </div>

        <div class="mb-4">
          クレボでは、お取引いただいたお客様に、光触媒抗菌コーティングを無料施工いたしております<br />
          （家屋1棟丸ごと抗菌サービス：感染対策をご希望の全てのお客様が対象となります）
        </div>

        <div>
          この他【SUUMO】【at
          home】【HOME’S】等の物件検索サイトに掲載されている他の案件に<br />
          ついても随時、内覧および仲介を承っております。<br />
          ご興味のある物件等がございましたら、クレボにご用命いただければ幸いです。
        </div>
      </div>
    </v-card>
    <ContactBtn />
    <Snackbar ref="snack" />
  </div>
</template>

<script>
import ContactBtn from "@/components/parts/contactBtn.vue";
import Snackbar from "@/components/snackbar.vue";
import axios from "axios";
export default {
  data() {
    return {
      parts1: [
        {
          t: "新築一戸建",
          i: "fas fa-home",
          a: "/estate/buy/new/kodate",
        },
        {
          t: "中古一戸建",
          i: "fas fa-home",
          a: "/estate/buy/used/kodate",
        },
        {
          t: "中古マンション",
          i: "fas fa-building",
          a: "/estate/buy/used/mansion",
        },
      ],

      parts2: [
        {
          t: "住まい",
          i: "fas fa-house-user",
          a: "/estate/rent/sumai",
        },
        {
          t: "事務所･店舗",
          i: "fas fa-store",
          a: "/estate/rent/store",
        },
      ],

      count: [0, 0, 0, 0, 0],
    };
  },
  components: {
    ContactBtn,
    Snackbar,
  },
  async mounted() {
    const response = await axios.post("/estate/count/each");
    this.count = [
      response.data.count0,
      response.data.count1,
      response.data.count2,
      response.data.count3,
      response.data.count4,
      response.data.count5,
    ];
    if (location.hash == "#register") {
      this.$refs.snack.snack = true;
      this.$refs.snack.message = "登録しました";
    }

    if (location.hash == "#delete") {
      this.$refs.snack.snack = true;
      this.$refs.snack.message = "登録解除しました";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/estate/index.scss";

.square-box {
  height: 256.8px;
}
</style>

